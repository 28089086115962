@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

@mixin transition($time, $type){
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}


@mixin sidebar-color($color){
  &:after{
    background: $color;
  }
}

@mixin bar-animation($type){
     -webkit-animation: $type 500ms linear 0s;
     -moz-animation: $type 500ms linear 0s;
     animation: $type 500ms 0s;
     -webkit-animation-fill-mode: forwards;
     -moz-animation-fill-mode: forwards;
     animation-fill-mode: forwards;
}

@mixin sidebar-active-color($font-color){
    .nav {
        li {
          &.active > a,
          &.active > a i,
          &.active > a[data-toggle="collapse"],
          &.active > a[data-toggle="collapse"] i,
          &.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon,
          &.active > a[data-toggle="collapse"] ~ div > ul > li.active > a {
            color: $font-color;
            opacity: 1;
          }
        }
    }
}

@mixin transition-opacity($time, $type){
    -webkit-transition: opacity $time $type;
    -moz-transition: opacity $time $type;
    -o-transition: opacity $time $type;
    -ms-transition: opacity $time $type;
    transition: opacity $time $type;
}

@mixin transform-translate-y-dropdown($value) {
    -webkit-transform:  translate3d(-20px,$value,0) !important;
       -moz-transform: translate3d(-20px,$value,0) !important;
       -o-transform: translate3d(-20px,$value,0) !important;
       -ms-transform: translate3d(-20px,$value,0) !important;
       transform: translate3d(-20px,$value,0) !important;
}

@mixin transform-translate-x($value){
     -webkit-transform:  translate3d($value, 0, 0);
        -moz-transform: translate3d($value, 0, 0);
        -o-transform: translate3d($value, 0, 0);
        -ms-transform: translate3d($value, 0, 0);
        transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value){
     -webkit-transform:  translate3d(0,$value,0) !important;
        -moz-transform: translate3d(0,$value,0) !important;
        -o-transform: translate3d(0,$value,0) !important;
        -ms-transform: translate3d(0,$value,0) !important;
        transform: translate3d(0,$value,0) !important;
}

@mixin transform-translate-y-fixed-plugin($value){
     -webkit-transform:  translate3d(0,$value,0) !important;
        -moz-transform: translate3d(0,$value,0) !important;
        -o-transform: translate3d(0,$value,0) !important;
        -ms-transform: translate3d(0,$value,0) !important;
        transform: translate3d(0,$value,0) !important;
}

@mixin icon-gradient($color, $bottomColor: #000){
    background: $color;
    background: -webkit-linear-gradient($color 0%, $bottomColor 80%);
    background: -o-linear-gradient($color 0%, $bottomColor 80%);
    background: -moz-linear-gradient($color 0%, $bottomColor 80%);
    background: linear-gradient($color 0%, $bottomColor 80%);
}

@mixin topbar-x-rotation(){
    @keyframes topbar-x {
      0% {top: 0px; transform: rotate(0deg); }
      45% {top: 6px; transform: rotate(145deg); }
      75% {transform: rotate(130deg); }
      100% {transform: rotate(135deg); }
    }
    @-webkit-keyframes topbar-x {
      0% {top: 0px; -webkit-transform: rotate(0deg); }
      45% {top: 6px; -webkit-transform: rotate(145deg); }
      75% {-webkit-transform: rotate(130deg); }
      100% { -webkit-transform: rotate(135deg); }
    }
    @-moz-keyframes topbar-x {
      0% {top: 0px; -moz-transform: rotate(0deg); }
      45% {top: 6px; -moz-transform: rotate(145deg); }
      75% {-moz-transform: rotate(130deg); }
      100% { -moz-transform: rotate(135deg); }
    }
}


@mixin topbar-back-rotation(){
    @keyframes topbar-back {
      0% { top: 6px; transform: rotate(135deg); }
      45% { transform: rotate(-10deg); }
      75% { transform: rotate(5deg); }
      100% { top: 0px; transform: rotate(0); }
    }

    @-webkit-keyframes topbar-back {
      0% { top: 6px; -webkit-transform: rotate(135deg); }
      45% { -webkit-transform: rotate(-10deg); }
      75% { -webkit-transform: rotate(5deg); }
      100% { top: 0px; -webkit-transform: rotate(0); }
    }

    @-moz-keyframes topbar-back {
      0% { top: 6px; -moz-transform: rotate(135deg); }
      45% { -moz-transform: rotate(-10deg); }
      75% { -moz-transform: rotate(5deg); }
      100% { top: 0px; -moz-transform: rotate(0); }
    }
}

@mixin bottombar-x-rotation(){
    @keyframes bottombar-x {
      0% {bottom: 0px; transform: rotate(0deg);}
      45% {bottom: 6px; transform: rotate(-145deg);}
      75% {transform: rotate(-130deg);}
      100% {transform: rotate(-135deg);}
    }
    @-webkit-keyframes bottombar-x {
      0% {bottom: 0px; -webkit-transform: rotate(0deg);}
      45% {bottom: 6px; -webkit-transform: rotate(-145deg);}
      75% {-webkit-transform: rotate(-130deg);}
      100% {-webkit-transform: rotate(-135deg);}
    }
    @-moz-keyframes bottombar-x {
      0% {bottom: 0px; -moz-transform: rotate(0deg);}
      45% {bottom: 6px; -moz-transform: rotate(-145deg);}
      75% {-moz-transform: rotate(-130deg);}
      100% {-moz-transform: rotate(-135deg);}
    }
}

@mixin bottombar-back-rotation{
    @keyframes bottombar-back {
      0% { bottom: 6px;transform: rotate(-135deg);}
      45% { transform: rotate(10deg);}
      75% { transform: rotate(-5deg);}
      100% { bottom: 0px;transform: rotate(0);}
    }
    @-webkit-keyframes bottombar-back {
      0% {bottom: 6px;-webkit-transform: rotate(-135deg);}
      45% {-webkit-transform: rotate(10deg);}
      75% {-webkit-transform: rotate(-5deg);}
      100% {bottom: 0px;-webkit-transform: rotate(0);}
    }
    @-moz-keyframes bottombar-back {
      0% {bottom: 6px;-moz-transform: rotate(-135deg);}
      45% {-moz-transform: rotate(10deg);}
      75% {-moz-transform: rotate(-5deg);}
      100% {bottom: 0px;-moz-transform: rotate(0);}
    }

}

@mixin sidebar-text-color($text-color){
    .nav {
      li {
        a,
        a i,
        a[data-toggle="collapse"],
        a[data-toggle="collapse"] i,
        a[data-toggle="collapse"] ~ div > ul > li .sidebar-mini-icon,
        a[data-toggle="collapse"] ~ div > ul > li > a {
          color: $text-color;
          opacity: .7;
        }

        &:hover:not(.active) > a,
        &:focus:not(.active) > a {
            opacity: 1;
        }
      }
    }

    .logo {
      .simple-text {
        color: $text-color;
      }
      &:after {
        background-color: $text-color;
        opacity: .4;
      }
    }

    .user {
      .info a span,
      .nav .sidebar-mini-icon,
      .nav .sidebar-normal {
        color: $text-color !important;
      }
      &:after {
        background-color: $text-color;
        opacity: .4;
      }
    }
}

@mixin badge-color($color) {
    border-color: $color;
    background-color: $color;
}
